import { makeStyles } from '@mui/styles';

const testStyles = makeStyles((theme) => ({
    div: {
        paddingLeft: 50,
        paddingTop: 15,
        paddingBottom: 15,
    }
}))

export default testStyles;